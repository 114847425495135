import slug from 'slug';
import appConfig from '@src/app.config';
import MediaChooser from '@components/media/MediaChooser';
import ColorPicker from '@components/elements/ColorPicker';
import CurrencyInput from '@components/elements/CurrencyInput';
import WereChangesAlert from '@components/alerts/WereChangesAlert';

export default {
    page: {
        title: 'Product Details',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        MediaChooser,
        ColorPicker,
        CurrencyInput,
        WereChangesAlert
    },

    props: {
        productId: {
            type: [Number, String],
            required: false,
            default: null
        }
    },

    data() {
        return {
            editMode: false,
            slugExists: false
        };
    },

    computed: {
        wereChanges() {
            if (!this.product || !this.editMode) {
                return false;
            }

            return JSON.stringify(this.product) !== this.initValueStringify;
        },

        breadcrumbsItems() {
            return [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Product List',
                    to: `/products?type=${
                        this.product.type ? 'other' : 'orthotics'
                    }`
                },
                {
                    text: 'Product Details',
                    active: true
                }
            ];
        }
    },

    async created() {
        if (!this.productId) {
            return;
        }

        this.editMode = true;
        this.product = await this.findProduct(this.productId);
        this.formatProductPriceToDollars();

        this.initValueStringify = JSON.stringify(this.product);

        if (this.questionnaires) {
            this.questionnaires = [...this.product.questionnaires];
        }
    },

    methods: {
        async onSaveProductButtonClick() {
            this.slugExists = false;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                const productWithoutFixedPrice = {
                    ...this.product,
                    price: Math.round(this.product.price * 100),
                    sale_price: Math.round(this.product.sale_price * 100)
                };

                if (!this.editMode) {
                    const product = await this.storeProduct({
                        product: productWithoutFixedPrice
                    });

                    this.$toaster('Product has been added!');

                    this.$router.push(
                        `/products/${product.id}/${
                            this.product.type ? 'edit-secondary' : 'edit'
                        }`
                    );

                    return;
                }

                const { id } = this.product;

                this.product = await this.updateProduct({
                    id,
                    product: productWithoutFixedPrice
                });

                this.formatProductPriceToDollars();
                this.initValueStringify = JSON.stringify(this.product);
                this.$toaster('Product has been updated!');
            } catch (err) {
                console.error(err);

                if (
                    err.response.status === 422 &&
                    err.response.data.errors &&
                    err.response.data.errors.slug
                ) {
                    this.slugExists = true;

                    return;
                }

                this.$toasterError('Product update error!');
            }
        },

        async onDuplicateProductButtonClick() {
            const confirmed = await this.$confirmationModal(
                'Do you want to duplicate this item?', {
                    title: 'Are you sure?'
                }
            );

            if (!confirmed) {
                return;
            }

            try {
                const product = await this.duplicateProduct(this.product.id);

                this.$router.push(`/products/${product.id}/edit`);

                await this.$nextTick();

                this.$toaster('Product has been duplicated!');
            } catch (err) {
                console.error(err);

                this.$toasterError('Product duplicating error!');
            }
        },

        formatProductPriceToDollars() {
            this.product.price /= 100;
            this.product.sale_price /= 100;
        },

        async onNameBlur() {
            if (!this.editMode && this.product.name) {
                this.product.slug = slug(this.product.name.toLowerCase());

                await this.$forceUpdate();
            }
        },

        async onSlugBlur() {
            if (this.product.slug) {
                this.product.slug = slug(this.product.slug.toLowerCase());

                await this.$forceUpdate();
            }
        }
    }
};
