<template>
    <awesome-table
        :labels="labels"
        :items="extensions"
        :options="{
            drag: true,
            sort: true,
            link: false
        }"
        :pagination="pagination"
        @add-button-click="$router.push(`/products/${productId}/extensions/new`)"
        @row-click="$router.push(`/products/${productId}/extensions/${$event.id}/edit`)"
        @mouse-wheel-click="$openNewTab(`/products/${productId}/extensions/${$event.id}/edit`)"
        @pagination-change="onPaginationChange"
        @elements-moved="onElementsMoved"
    >
        <template #[`items.actions`]="{ item }">
            <a
                href="#"
                class="action-icon"
                @click.stop.prevent="onDeleteExtension(item.id)"
            >
                <i class="far fa-trash-alt" />
            </a>
        </template>
    </awesome-table>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        productId: {
            type: String,
            required: true,
            default: ''
        }
    },

    data() {
        return {
            extensions: [],
            labels: [
                'name',
                { value: 'price', filter: 'variantFixedPrice' },
                {
                    value: 'created_at',
                    filter: 'formatDate'
                },
                {
                    value: 'updated_at',
                    filter: 'formatDate'
                },
                { value: 'actions', sortable: false }
            ],
            pagination: {
                currentPage: 1,
                perPage: 10,
                total: 0,
                sortBy: 'created_at',
                descending: true
            }
        };
    },

    mounted() {
        this.fetchExtensions();
    },

    methods: {
        ...mapActions({
            getExtensions: 'productExtensions/index',
            deleteExtension: 'productExtensions/delete',
            changeOrder: 'productExtensions/changeOrder'
        }),

        async fetchExtensions() {
            try {
                const options = {
                    productId: this.productId,
                    ...this.pagination
                };

                const { rows, count } = await this.getExtensions(options);

                if (options.currentPage !== this.pagination.currentPage) {
                    return;
                }

                this.extensions = rows;
                this.pagination.pages = Math.ceil(
                    count / this.pagination.perPage
                );
                this.pagination.total = count;
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async onDeleteExtension(id) {
            const confirmed = await this.$confirmationModal(
                'Do you want to delete this extension?', {
                    title: 'Are you sure?'
                }
            );

            if (!confirmed) {
                return;
            }

            try {
                await this.deleteExtension(id);

                this.extensions = this.extensions.filter(
                    item => item.id !== id
                );

                this.$toaster('Extension has been removed!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        onPaginationChange(pagination) {
            this.pagination = {
                ...pagination
            };

            this.fetchExtensions();
        },

        async onElementsMoved(elements) {
            try {
                await this.changeOrder({ productId: this.productId, elements });

                this.$toaster('Order has been changed!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        }
    }
}
</script>
