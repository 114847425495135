<template>
    <div>
        <div class="card-box pt-0">
            <h5 class="col-12 text-uppercase bg-light p-2 mt-0 mb-3">
                General
            </h5>
            <div class="form-group col-12">
                <wysiwyg-editor
                    id="title"
                    v-model="subactivityQuestion.title"
                    label="Title"
                />
            </div>
            <div class="form-group col-12">
                <wysiwyg-editor
                    id="subtitle"
                    v-model="subactivityQuestion.subtitle"
                    label="Subtitle"
                />
            </div>

            <div class="form-group col-12">
                <label for="identifier">Question identifier:</label>
                <input
                    id="identifier"
                    v-model="subactivityQuestion.questionIdentifier"
                    type="text"
                    class="form-control"
                />
            </div>
        </div>

        <div class="card-box">
            <question-answers-options-table
                :key="subactivityQuestion.options.length"
                v-model="subactivityQuestion.options"
                :parsed-options="subactivityQuestion.options"
                :product-questionnaires="questionnaires"
                is-subactivity-question
                @clear-all-options="onClearAllOption"
                @clear-single-option="onClearSingleOption"
            />
        </div>
    </div>
</template>

<script>
import WysiwygEditor from '@components/elements/WysiwygEditor';
import QuestionAnswersOptionsTable from '@components/questions/QuestionAnswersOptionsTable';

export default {
    components: {
        WysiwygEditor,
        QuestionAnswersOptionsTable
    },

    props: {
        value: {
            type: Object,
            required: true
        },
        questionnaires: {
            type: Array,
            required: false,
            default: () => []
        }
    },

    data() {
        return {
            subactivityQuestion: {}
        };
    },

    watch: {
        subactivityQuestion: {
            handler() {
                this.$emit('input', this.subactivityQuestion);
            },
            deep: true
        }
    },

    created() {
        this.subactivityQuestion = { ...this.value };
    },

    methods: {
        switchSubactivityQuestion() {
            if (!this.subactivityQuestion) {
                this.subactivityQuestion = {
                    options: []
                };
            } else {
                this.subactivityQuestion = null;
            }
        },

        onClearSingleOption(index) {
            this.subactivityQuestion.options.splice(index, 1);
        },

        onClearAllOption() {
            this.subactivityQuestion.options = [];
        }
    }
};
</script>
