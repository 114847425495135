<template>
    <div>
        <div class="card-box pt-0">
            <h5 class=" col-12 text-uppercase bg-light p-2 mt-0 mb-3">
                General
            </h5>
            <div class="form-group col-12">
                <wysiwyg-editor
                    id="title"
                    v-model="navigationQuestion.title"
                    label="Title"
                />
            </div>
            <div class="form-group col-12">
                <wysiwyg-editor
                    id="subtitle"
                    v-model="navigationQuestion.subtitle"
                    label="Subtitle"
                />
            </div>

            <div class="row">
                <div class="form-group col-4 ml-2">
                    <b-form-checkbox
                        v-model="navigationQuestion.optional"
                        switch
                    >
                        Optional
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-model="navigationQuestion.hide_in_summary"
                        switch
                    >
                        Hide in summary
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-model="navigationQuestion.hide_next_button"
                        switch
                    >
                        Hide next button
                    </b-form-checkbox>
                </div>
            </div>

            <div class="form-group col-12">
                <label for="answerVariable">Save answer to:</label>
                <input
                    id="answerVariable"
                    v-model="navigationQuestion.answer_variable"
                    type="text"
                    class="form-control"
                />
            </div>

            <div class="form-group col-12">
                <div class="alert alert-blue border-0">
                    <i class="fas fa-info mr-1" />
                    The best option for naming a variable is to put it in <b>{}</b>,
                    e.g. <b>{FirstName}</b>
                </div>
            </div>

            <div class="form-group col-12">
                <label for="identifier">Question identifier:</label>
                <input
                    id="identifier"
                    v-model="navigationQuestion.identifier"
                    type="text"
                    class="form-control"
                />
            </div>

            <h5 class="text-uppercase bg-light col-12 p-2 my-3">
                Bubble Talk
            </h5>
            <div class="form-group col-12">
                <b-form-checkbox
                    v-model="navigationQuestion.is_bubble_talk"
                    switch
                >
                    Enabled
                </b-form-checkbox>
            </div>

            <question-bubble-talk
                v-if="navigationQuestion.is_bubble_talk"
                :bubble-text="navigationQuestion.bubble_text"
                :bubble-image="navigationQuestion.bubble_image"
                :bubble-background="
                    navigationQuestion.bubble_background || '#EEEEEE'
                "
                @bubble-talk="onAddBubbleTalk"
            />

            <h5 class="col-12 text-uppercase bg-light p-2 my-3">
                Analytics
            </h5>
            <div class="form-group col-12">
                <label for="question_step_name">Step Name</label>
                <input
                    id="question_step_name"
                    v-model="navigationQuestion.step_name"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <label>DataLayer Tag</label>
                <v-select
                    v-model="navigationQuestion.data_layer_tag"
                    :options="dataLayerTagOptions"
                />
            </div>
            <div class="form-group col-12">
                <b-form-checkbox
                    v-model="navigationQuestion.short_answer"
                    switch
                >
                    Send answer as YES / NO
                </b-form-checkbox>
            </div>
        </div>

        <div class="card-box">
            <question-answers-options-table
                :key="navigationQuestion.options.length"
                v-model="navigationQuestion.options"
                :parsed-options="navigationQuestion.options"
                :product-questionnaires="questionnaires"
                is-navigation-question
                @clear-all-options="onClearAllOption"
                @clear-single-option="onClearSingleOption"
            />
        </div>
    </div>
</template>

<script>
import WysiwygEditor from '@components/elements/WysiwygEditor';
import QuestionAnswersOptionsTable from '@components/questions/QuestionAnswersOptionsTable';
import QuestionBubbleTalk from '@components/questions/QuestionBubbleTalk';

export default {
    components: {
        WysiwygEditor,
        QuestionAnswersOptionsTable,
        QuestionBubbleTalk
    },

    props: {
        value: {
            type: Object,
            required: true,
        },
        questionnaires: {
            type: Array,
            required: false,
            default: () => []
        }
    },

    data() {
        return {
            navigationQuestion: {},
            dataLayerTagOptions: [
                'Activity Type',
                'Sub-activity Type',
                'Health Condition',
                'Men-Women',
                'Shoe type',
                'Removal inserts',
                'Pain Type',
                'Comment',
                'Everyday-Sports',
                'Color',
                'Changing question'
            ]
        };
    },

    watch: {
        navigationQuestion: {
            handler() {
                this.$emit('input', this.navigationQuestion);
            },
            deep: true
        }
    },

    created() {
        this.navigationQuestion = { ...this.value };
    },

    methods: {
        onClearSingleOption(index) {
            this.navigationQuestion.options.splice(index, 1);
        },

        onClearAllOption() {
            this.navigationQuestion.options = [];
        },

        onAddBubbleTalk(bubbleTalk) {
            this.navigationQuestion = {
                ...this.navigationQuestion,
                ...bubbleTalk
            };
        }
    }
};
</script>
