<template>
    <div class="card-box">
        <h5 class="text-uppercase bg-light p-2 mt-0 mb-4">
            Product colors
        </h5>

        <awesome-table
            :labels="labels"
            :items="colors"
            :options="{
                drag: true,
                sort: true,
                isCard: false,
                link: false
            }"
            :pagination="pagination"
            @add-button-click="$router.push(`/products/${productId}/colors/new`)"
            @row-click="$router.push(`/products/${productId}/colors/${$event.id}/edit`)"
            @mouse-wheel-click="$openNewTab(`/products/${productId}/colors/${$event.id}/edit`)"
            @pagination-change="onPaginationChange"
            @elements-moved="onElementsMoved"
        >
            <template #[`items.hex`]="{ value }">
                <span
                    :style="{
                        'background-color': value,
                        display: 'block',
                        margin: 'auto'
                    }"
                    class="colored-block"
                />
            </template>
            <template #[`items.actions`]="{ item }">
                <a
                    href="#"
                    class="action-icon"
                    @click.stop.prevent="removeColor(item.id)"
                >
                    <i class="far fa-trash-alt" />
                </a>
            </template>
        </awesome-table>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        productId: {
            type: String,
            required: true,
            default: ''
        }
    },

    data() {
        return {
            colors: [],
            labels: [
                'name',
                { value: 'hex', text: 'Color', sortable: false },
                {
                    value: 'created_at',
                    filter: 'formatDate'
                },
                {
                    value: 'updated_at',
                    filter: 'formatDate'
                },
                { value: 'actions', sortable: false }
            ],
            pagination: {
                currentPage: 1,
                perPage: 10,
                total: 0,
                sortBy: 'created_at',
                descending: true
            }
        };
    },

    mounted() {
        this.fetchColors();
    },

    methods: {
        ...mapActions({
            getProductColors: 'products/getProductColors',
            deleteColor: 'products/deleteProductColor',
            changeOrder: 'products/changeProductColorsOrder'
        }),

        async fetchColors() {
            try {
                const response = await this.getProductColors({
                    productId: this.productId,
                    ...this.pagination
                });

                this.colors = [...response.rows];
                this.pagination.pages = Math.ceil(
                    response.count / this.pagination.perPage
                );
                this.pagination.total = response.count;
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async removeColor(id) {
            const confirmed = await this.$confirmationModal(
                'Do you want to delete this color?', {
                    title: 'Are you sure?'
                }
            );

            if (!confirmed) {
                return;
            }

            try {
                await this.deleteColor(id);

                this.colors = this.colors.filter(
                    item => item.id !== id
                );

                this.$toaster('Color has been removed!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        onPaginationChange(pagination) {
            this.pagination = {
                ...pagination
            };

            this.fetchColors();
        },

        async onElementsMoved(elements) {
            try {
                await this.changeOrder({ productId: this.productId, elements });

                this.$toaster('Order has been changed!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        }
    }
};
</script>
