<template>
    <div class="row">
        <div class="form-group col-12">
            <wysiwyg-editor
                v-model="bubbleTalk.bubble_text"
                class="col-12"
                label="Text"
            />

            <media-chooser
                v-model="bubbleTalk.bubble_image"
                add-buff
                class="col-12"
                label="Image"
            />

            <div class="col-12">
                <label>Background Color</label>
                <color-picker
                    v-model="bubbleTalk.bubble_background"
                    :color="bubbleBackground"
                />
            </div>
        </div>
    </div>
</template>

<script>
import MediaChooser from '@components/media/MediaChooser';
import ColorPicker from '@components/elements/ColorPicker';
import WysiwygEditor from '@components/elements/WysiwygEditor';

export default {
    components: {
        ColorPicker,
        MediaChooser,
        WysiwygEditor
    },

    props: {
        bubbleText: {
            type: String,
            required: false,
            default: ''
        },
        bubbleImage: {
            type: Object,
            required: false,
            default: null
        },
        bubbleBackground: {
            type: String,
            required: false,
            default: '#EEEEEE'
        }
    },

    data() {
        return {
            bubbleTalk: {}
        };
    },

    watch: {
        bubbleTalk: {
            handler() {
                this.$emit('bubble-talk', this.bubbleTalk);
            },

            deep: true
        }
    },

    mounted() {
        this.bubbleTalk = {
            bubble_text: this.bubbleText,
            bubble_image: this.bubbleImage,
            bubble_background: this.bubbleBackground
        };
    }
};
</script>
