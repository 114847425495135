var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-box"},[_c('h5',{staticClass:"text-uppercase bg-light p-2 mt-0 mb-4"},[_vm._v("\n        Product colors\n    ")]),_c('awesome-table',{attrs:{"labels":_vm.labels,"items":_vm.colors,"options":{
            drag: true,
            sort: true,
            isCard: false,
            link: false
        },"pagination":_vm.pagination},on:{"add-button-click":function($event){return _vm.$router.push(("/products/" + _vm.productId + "/colors/new"))},"row-click":function($event){return _vm.$router.push(("/products/" + _vm.productId + "/colors/" + ($event.id) + "/edit"))},"mouse-wheel-click":function($event){return _vm.$openNewTab(("/products/" + _vm.productId + "/colors/" + ($event.id) + "/edit"))},"pagination-change":_vm.onPaginationChange,"elements-moved":_vm.onElementsMoved},scopedSlots:_vm._u([{key:"items.hex",fn:function(ref){
        var value = ref.value;
return [_c('span',{staticClass:"colored-block",style:({
                    'background-color': value,
                    display: 'block',
                    margin: 'auto'
                })})]}},{key:"items.actions",fn:function(ref){
                var item = ref.item;
return [_c('a',{staticClass:"action-icon",attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.removeColor(item.id)}}},[_c('i',{staticClass:"far fa-trash-alt"})])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }