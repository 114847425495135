<template>
    <div>
        <h5 class="text-uppercase bg-light px-2 mt-0 mb-3">
            <div class="d-flex justify-content-between align-items-center">
                <span>Options</span>
                <div>
                    <button
                        v-if="questionType === 'multiple' && !isNoneButton"
                        class="btn btn-info m-1"
                        type="button"
                        @click="addOption(true)"
                    >
                        <i class="fas fa-plus-square mr-1" />
                        New None Option
                    </button>
                    <button
                        class="btn btn-warning m-1"
                        type="button"
                        @click="addOption(false)"
                    >
                        <i class="fas fa-plus-square mr-1" />
                        New Option
                    </button>
                    <button
                        v-if="options.length"
                        class="btn btn-danger m-1"
                        type="button"
                        @click="onClickClearButton"
                    >
                        <i class="fe-trash-2 mr-1" />
                        Clear Options
                    </button>
                </div>
            </div>
        </h5>

        <div class="row">
            <div :key="options.length" class="col-12 p-2">
                <b-card
                    v-for="(option, index) in options"
                    :key="index + 'option'"
                    class="card-custom-border-color"
                >
                    <b-card-title
                        class="d-flex flex-row justify-content-between"
                    >
                        <h4>
                            {{
                                `${index + 1}
                                ${option.is_none_button ? '(None Button)' : ''}`
                            }}
                        </h4>
                        <div
                            class="col-2 d-flex justify-content-end align-items-start"
                        >
                            <button
                                v-if="index"
                                class="btn btn-danger waves-effect waves-light mr-1"
                                type="button"
                                @click="moveElement(index, index - 1)"
                            >
                                <i class="fas fa-arrow-up" />
                            </button>
                            <button
                                v-if="index !== options.length - 1"
                                class="btn btn-danger waves-effect waves-light mr-1"
                                type="button"
                                @click="moveElement(index, index + 1)"
                            >
                                <i class="fas fa-arrow-down" />
                            </button>
                            <button
                                class="btn btn-outline-danger btn-small mx-2"
                                type="button"
                                @click="deleteOption(index)"
                            >
                                <i class="fe-trash" />
                            </button>
                        </div>
                    </b-card-title>

                    <b-row v-if="option.is_none_button">
                        <b-col md="12">
                            <b-form-group
                                id="fieldset-horizontal"
                                label="Content"
                                label-for="non_button_input"
                            >
                                <b-form-input
                                    id="non_button_input"
                                    v-model="option.content"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group
                                id="fieldset-horizontal"
                                label="Text Button"
                                label-for="non_button_input"
                            >
                                <b-form-input
                                    id="non_button_input"
                                    v-model="option.textButton"
                                />
                            </b-form-group>
                        </b-col>
                        <media-chooser
                            v-model="option.icon"
                            folder="questions"
                            label="Background Image"
                            class="col-12"
                        />
                    </b-row>

                    <b-row v-else-if="isSubactivityQuestion">
                        <b-col md="12">
                            <b-form-group label="Content">
                                <b-form-input v-model="option.content" />
                            </b-form-group>
                            <b-form-group label="Identifier">
                                <b-form-input v-model="option.identifier" />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row v-else>
                        <div class="col-12 my-2">
                            <b-form-group label="Content">
                                <b-form-input v-model="option.content" />
                            </b-form-group>
                            <b-form-group
                                v-if="!isNavigationQuestion"
                                label="Identifier"
                            >
                                <b-form-input v-model="option.identifier" />
                            </b-form-group>

                            <b-form-checkbox
                                v-if="
                                    questionType === 'single' &&
                                        !isNavigationQuestion
                                "
                                v-model="option.showTitle"
                                name="enabled"
                                switch
                            >
                                Show Title in questionnaire
                            </b-form-checkbox>
                        </div>
                        <div v-if="editMode" class="col-12">
                            <b-form-group label="Redirect">
                                <b-form-select
                                    v-model="option.redirectQuestionId"
                                    :options="subquestionOptions"
                                    text-field="title"
                                    value-field="id"
                                />
                            </b-form-group>
                        </div>
                        <div v-if="productQuestionnaires.length" class="col-12">
                            <b-form-group label="Redirect">
                                <b-form-select
                                    v-model="option.redirectQuestionnaireSlug"
                                    :options="productQuestionnaires"
                                    text-field="name"
                                    value-field="name"
                                />
                            </b-form-group>
                        </div>
                        <div v-if="productRelated" class="col-12">
                            <b-form-group
                                v-if="!option.assignSku"
                                label="Product"
                            >
                                <b-form-select
                                    v-model="option.relatedProductSlug"
                                    :options="products"
                                    text-field="name"
                                    value-field="slug"
                                    @input="
                                        setTitleWithProductName($event, index)
                                    "
                                />
                            </b-form-group>
                            <b-form-group>
                                <b-form-checkbox
                                    v-model="option.assignSku"
                                    name="enabled"
                                    switch
                                    @change="option.relatedProductSlug = null"
                                >
                                    Assign SKU
                                </b-form-checkbox>
                            </b-form-group>
                            <b-form-group>
                                <b-form-group
                                    v-if="option.assignSku"
                                    label="SKU"
                                >
                                    <b-form-input v-model="option.sku" />
                                </b-form-group>
                            </b-form-group>
                        </div>
                        <div
                            v-if="
                                questionType === 'multiple' ||
                                    questionType === 'single'
                            "
                            class="col-12"
                        >
                            <b-form-group>
                                <b-form-checkbox
                                    v-model="
                                        option.assignPostPurchaseQuestionnaire
                                    "
                                    switch
                                    @change="
                                        option.relatedPostPurchaseQuestionnaireId = null
                                    "
                                >
                                    Post purchase
                                </b-form-checkbox>
                            </b-form-group>
                            <b-form-group
                                v-if="option.assignPostPurchaseQuestionnaire"
                                label="Post Purchase Questionnaire"
                            >
                                <b-form-select
                                    v-model="
                                        option.relatedPostPurchaseQuestionnaireId
                                    "
                                    :options="postPurchaseQuestionnaires"
                                    text-field="name"
                                    value-field="id"
                                />
                            </b-form-group>
                        </div>
                        <media-chooser
                            v-model="option.icon"
                            folder="questions"
                            label="Background Image"
                            class="col-12"
                        />
                        <b-col
                            v-if="questionType === 'multiple'"
                            class="pt-2"
                            md="12"
                        >
                            <b-form-checkbox
                                v-model="option.activeSubanswer"
                                switch
                            >
                                Contains sub-answers
                            </b-form-checkbox>
                        </b-col>
                        <b-col
                            v-if="
                                questionType === 'multiple' ||
                                    questionType === 'single'
                            "
                            class="pt-2"
                            md="12"
                        >
                            <b-form-checkbox
                                v-model="option.activeByDefault"
                                switch
                            >
                                Mark active by default
                            </b-form-checkbox>
                        </b-col>
                    </b-row>


                    <div v-if="activeByDefaultError" class="alert alert-danger border-0 mt-2">
                        <i class="fas fa-exclamation-triangle mr-1" />
                        Only one option can be marked as active!
                    </div>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import MediaChooser from '@components/media/MediaChooser';

export default {
    components: {
        MediaChooser
    },

    props: {
        questionType: {
            required: false,
            type: String,
            default: 'single'
        },
        productRelated: {
            type: Boolean,
            required: false,
            default: false
        },
        parsedOptions: {
            type: Array,
            required: true,
            default: () => []
        },
        editMode: {
            type: Boolean,
            required: false,
            default: false
        },
        isNavigationQuestion: {
            type: Boolean,
            required: false,
            default: false
        },
        isSubactivityQuestion: {
            type: Boolean,
            required: false,
            default: false
        },
        subquestions: {
            type: Array,
            required: false,
            default: () => []
        },
        products: {
            type: Array,
            required: false,
            default: () => []
        },
        productQuestionnaires: {
            type: Array,
            required: false,
            default: () => []
        }
    },

    data: () => ({
        options: [],
        postPurchaseQuestionnaires: []
    }),

    computed: {
        isNoneButton() {
            return !!this.options.find(option => option.is_none_button);
        },

        isEveryOptionFilled() {
            for (const option of this.options) {
                const allFieldsFilled =
                    option.content && (option.icon || this.isSubactivityQuestion);

                if (!allFieldsFilled) {
                    return false;
                }
            }

            return true;
        },

        activeByDefaultError() {
            if (this.questionType === 'single') {
                const optionsActiveByDefault = this.options.filter(
                    option => option.activeByDefault
                );

                if (optionsActiveByDefault.length > 1) {
                    return true;
                }
            }

            return false;
        },

        subquestionOptions() {
            const subquestions = this.subquestions.map(question => ({
                ...question,
                title: question.title.replace(/<[^>]*>?/gm, '')
            }));

            const pages = [
                {
                    title: 'Cart page',
                    id: '/cart'
                },
                {
                    title: 'Checkout page',
                    id: '/checkout'
                }
            ]

            return [
                { id: null, title: 'No redirect' },
                { title: '- Subquestions -', disabled: true },
                ...subquestions,
                { title: '- Pages -', disabled: true },
                ...pages
            ]
        }
    },

    watch: {
        options: {
            handler(newValue) {
                this.$emit('input', [...newValue]);
            },
            deep: true
        },

        parsedOptions: {
            handler() {
                if (this.editMode) {
                    this.options = [...this.parsedOptions];
                }
            },
            immediate: true
        }
    },

    async mounted() {
        if (!this.editMode) {
            this.options = [...this.parsedOptions];
        }

        this.postPurchaseQuestionnaires = await this.getQuestionnaires({
            postPurchaseOnly: true
        });
    },

    methods: {
        ...mapActions({
            getQuestionnaires: 'questionnaires/index'
        }),

        addOption(isNoneOption) {
            if (!this.isEveryOptionFilled) {
                this.$toasterWarning('Fill every option!');

                return;
            }

            if (isNoneOption) {
                this.options.unshift({
                    content: '',
                    icon: null,
                    is_none_button: true
                });

                return;
            }

            const option = {};

            if (this.questionType === 'single') {
                option.showTitle = true;
            }

            this.options = [...this.options, { ...option }];
        },

        onClickClearButton() {
            this.$emit('clear-all-options');
        },

        deleteOption(index) {
            if (!this.editMode) {
                this.options = this.options.splice(index, 1);
            }

            this.$emit('clear-single-option', index);
        },

        moveElement(from, to) {
            this.options.splice(to, 0, this.options.splice(from, 1)[0]);
        },

        setTitleWithProductName(slug, index) {
            const product = this.products.find(item => item.slug === slug);

            if (product) {
                this.options[index].content = product.name;
            }
        }
    }
};
</script>

<style>
.card-custom-border-color {
    border: 1px solid #d1d1d1;
}
</style>
