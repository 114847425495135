<template>
    <layout>
        <were-changes-alert
            :were-changes="wereChanges"
            @save="onSaveProductButtonClick"
        />
        <page-header :title="pageTitle" :items="breadcrumbsItems" />

        <div class="card-box">
            <div class="text-center">
                <router-link to="/products">
                    <button
                        type="button"
                        class="btn w-sm btn-light waves-effect mx-1"
                    >
                        Cancel
                    </button>
                </router-link>
                <a
                    v-if="editMode"
                    :href="previewUrl"
                    target="_blank"
                    class="btn btn-info mx-1"
                >
                    <i class="far fa-eye mr-1" />
                    Preview
                </a>
                <button
                    type="button"
                    class="btn w-sm btn-success waves-effect waves-light mx-1"
                    @click="onSaveProductButtonClick"
                >
                    <i class="fas fa-save mr-1" />
                    Save
                </button>
                <button
                    v-if="editMode"
                    type="button"
                    class="btn w-sm btn-secondary waves-effect waves-light mx-1"
                    @click="onDuplicateProductButtonClick"
                >
                    <i class="far fa-copy mr-1" />
                    Duplicate
                </button>
            </div>
        </div>

        <div class="card">
            <ul class="nav nav-pills navtab-bg nav-justified m-2">
                <li v-for="(tab, index) in tabs" :key="index" class="nav-item">
                    <a
                        href="#"
                        data-toggle="tab"
                        aria-expanded="false"
                        class="nav-link"
                        :class="{
                            active: activeTab === tab.value
                        }"
                        @click.prevent="changeTab(tab)"
                    >
                        {{ tab.text }}
                    </a>
                </li>
            </ul>
        </div>

        <div v-show="activeTab === 'product'">
            <div class="card-box">
                <h5 class="text-uppercase bg-light p-2 mt-0 mb-3">
                    General
                </h5>
                <div class="form-group mb-3">
                    <label for="product-name">
                        Product Name
                        <span class="text-danger">*</span>
                    </label>
                    <input
                        id="product-name"
                        v-model="product.name"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': $v.product.name.$error
                        }"
                        placeholder="Product Name"
                        @blur="onNameBlur"
                    />
                </div>
                <div class="form-group mb-3">
                    <label for="product-slug">
                        Slug
                        <span class="text-danger">*</span>
                    </label>
                    <input
                        id="product-slug"
                        v-model="product.slug"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': $v.product.slug.$error || slugExists
                        }"
                        placeholder="Product slug"
                        @blur="onSlugBlur"
                    />
                    <div v-if="slugExists" class="invalid-feedback">
                        Slug already exists.
                    </div>
                </div>
                <div class="form-group mb-3">
                    <label for="product-subnameTitle">
                        Product Description Headline
                    </label>
                    <input
                        id="product-subnameTitle"
                        v-model="product.subname_title"
                        class="form-control"
                        placeholder="Product Description Headline"
                        type="text"
                    />
                </div>
                <div class="form-group mb-3">
                    <label for="product-subname">
                        Product Description
                    </label>
                    <textarea
                        id="product-subname"
                        v-model="product.subname"
                        class="form-control"
                        placeholder="Product Description"
                        rows="3"
                    />
                </div>
                <div class="form-group mb-3">
                    <label for="product-title">
                        Title
                    </label>
                    <input
                        id="product-title"
                        v-model="product.title"
                        type="text"
                        class="form-control"
                        placeholder="Product subname"
                    />
                </div>
                <div class="form-group mb-3">
                    <label for="product-pain_related_title">
                        Pain Related Title
                    </label>
                    <input
                        id="product-pain_related_title"
                        v-model="product.pain_related_title"
                        type="text"
                        class="form-control"
                        placeholder="Pain related title"
                    />
                </div>
                <div class="form-group mb-3">
                    <label for="product-direct_link_title">
                        Direct Link Title
                    </label>
                    <input
                        id="product-direct_link_title"
                        v-model="product.direct_link_title"
                        type="text"
                        class="form-control"
                        placeholder="Direct link title"
                    />
                </div>
                <div class="form-group mb-3">
                    <label for="sku">SKU Number</label>
                    <input
                        id="sku"
                        v-model="product.sku"
                        type="text"
                        class="form-control"
                        placeholder="Enter amount"
                    />
                </div>

                <h5 class="text-uppercase mt-0 mb-3 bg-light p-2">
                    Pricing
                </h5>
                <div class="row">
                    <div class="col-6 form-group mb-3">
                        <label for="product_price">
                            Price <span class="text-danger">*</span>
                        </label>
                        <currency-input
                            id="product_price"
                            v-model.number="product.price"
                            type="text"
                            class="form-control"
                            :class="{
                                'is-invalid': $v.product.price.$error
                            }"
                            placeholder="Enter amount"
                        />
                    </div>

                    <div class="col-6 form-group mb-3">
                        <label for="sale_price">Sale Price </label>
                        <currency-input
                            id="sale_price"
                            v-model.number="product.sale_price"
                            class="form-control"
                            placeholder="Enter amount"
                        />
                    </div>
                </div>

                <div>
                    <h5 class="text-uppercase mt-0 bg-light p-2">
                        Quantity selection
                    </h5>
                    <div
                        v-for="(quantity, index) in product.quantity_selection"
                        :key="index"
                        class="row"
                    >
                        <div class="col-3 col-lg-2 form-group mb-3">
                            <label for="product_bundle_size">
                                Quantity
                            </label>
                            <input
                                id="product_bundle_size"
                                v-model.number="quantity.value"
                                type="number"
                                min="1"
                                class="form-control"
                                placeholder="Enter number"
                            />
                        </div>

                        <div class="col-4 col-lg-3 form-group mb-3">
                            <label for="product_bundle_text">
                                Qty text
                            </label>
                            <input
                                id="product_bundle_text"
                                v-model="quantity.qtyText"
                                class="form-control"
                                type="text"
                                placeholder="Enter text"
                            />
                        </div>

                        <div class="col-5 col-lg-4 form-group mb-3">
                            <label for="product_bundle_text">
                                Price text
                            </label>
                            <input
                                id="product_bundle_text"
                                v-model="quantity.priceText"
                                class="form-control"
                                type="text"
                                placeholder="Enter text"
                            />
                        </div>

                        <div class="col-12 col-lg-3 form-group mb-3 d-flex align-items-end">
                            <button
                                class="btn btn-success waves-effect waves-light"
                                style="min-width: 130px"
                                type="button"
                                :disabled="quantity.default"
                                @click="markAsDefault(index)"
                            >
                                <i v-if="quantity.default" class="mdi mdi-checkbox-marked-circle" />
                                {{ quantity.default ? 'Default' : 'Mark as default' }}
                            </button>

                            <button
                                class="btn btn-danger waves-effect waves-light ml-2"
                                type="button"
                                @click="deleteQuantitySelection(index)"
                            >
                                <i class="far fa-trash-alt" />
                            </button>
                        </div>
                    </div>
                    <button
                        class="btn btn-info waves-effect waves-light mb-2"
                        type="button"
                        @click="addQuantitySelection"
                    >
                        <i class="fas fa-plus" />
                    </button>
                </div>
            </div>

            <div class="card-box">
                <h5 class="text-uppercase mt-0 mb-3 bg-light p-2">
                    Post Purchase
                </h5>

                <div class="ml-2">
                    <b-form-checkbox
                        v-model="product.ask_for_removable_inserts"
                        name="enabled"
                        switch
                    >
                        Ask for Removable Inserts
                    </b-form-checkbox>
                </div>
                <div
                    v-if="!product.ask_for_removable_inserts"
                    class="col-12 ml-1 mt-1"
                >
                    <div
                        v-for="(option, index) in insertsLengthOptions"
                        :key="index"
                        class="radio radio-primary"
                    >
                        <input
                            :id="option.text"
                            v-model="product.full_length_inserts"
                            type="radio"
                            name="customRadio"
                            :value="option.value"
                        />
                        <label :for="option.text">
                            {{ option.text }}
                        </label>
                    </div>
                </div>
            </div>

            <product-informative-sections
                :key="`${product.id}-informativeSections`"
                v-model="product.informative_sections"
                class="card-box"
            />

            <product-benefits
                :key="`${product.id}-benefits`"
                v-model="product.benefits"
                class="card-box"
            />

            <div class="card-box">
                <h5 class="text-uppercase mt-0 mb-3 bg-light p-2">
                    Options
                </h5>

                <div class="ml-2">
                    <b-form-checkbox
                        v-model="product.video_first"
                        name="enabled"
                        switch
                    >
                        Slider - Show Video First
                    </b-form-checkbox>
                </div>
                <div class="ml-2 mt-1">
                    <b-form-checkbox
                        v-model="product.ikf_enabled"
                        name="enabled"
                        switch
                    >
                        IKF Enabled
                    </b-form-checkbox>
                </div>
                <div class="ml-2 mt-1">
                    <b-form-checkbox
                        v-model="product.hide_main_cta"
                        name="enabled"
                        switch
                    >
                        Hide Main CTA, Price
                    </b-form-checkbox>
                </div>
                <div class="form-group col-3 mt-1">
                    <label>Hebrew Name</label>
                    <input
                        v-model="product.hebrew_name"
                        class="form-control"
                    />
                </div>
            </div>

            <product-colors
                v-if="editMode && product.id"
                :product-id="product.id"
            />
        </div>

        <div v-show="activeTab === 'shop'">
            <div class="card-box">
                <div class="row m-0">
                    <h5 class="col-12 text-uppercase mt-0 mb-2 bg-light p-2">
                        Shop
                    </h5>
                    <media-chooser
                        v-model="product.shop_image"
                        add-buff
                        label="Image"
                        class="col-6"
                        recommended-size="570x620"
                    />
                    <media-chooser
                        v-model="product.shop_image_secondary"
                        add-buff
                        label="Mouseover Image"
                        class="col-6"
                        recommended-size="570x620"
                    />
                    <div class="form-group col-12">
                        <label>Name</label>
                        <textarea
                            v-model="product.shop_name"
                            class="form-control"
                            rows="1"
                        />
                    </div>
                    <div class="form-group col-12">
                        <label>Tooltip</label>
                        <textarea
                            v-model="product.shop_tooltip"
                            class="form-control"
                            rows="2"
                        />
                    </div>
                    <div class="form-group col-12">
                        <label>Reviews Number</label>
                        <input
                            v-model="product.shop_reviews_number"
                            class="form-control"
                        />
                    </div>
                    <div class="form-group col-12">
                        <b-form-checkbox
                            v-model="product.shop_ikf_text_sync"
                            name="enabled"
                            switch
                        >
                            Sync Text with IKF
                        </b-form-checkbox>
                    </div>
                    <div
                        v-if="!product.shop_ikf_text_sync"
                        class="form-group col-12"
                    >
                        <label>IKF Text</label>
                        <input
                            v-model="product.shop_ikf_text"
                            class="form-control"
                        />
                    </div>
                    <h5 class="col-12 text-uppercase bg-light p-2">
                        Patch
                    </h5>
                    <div class="col-12 my-2">
                        <b-form-checkbox
                            v-model="product.shop_patch.enabled"
                            name="enabled"
                            switch
                        >
                            Enabled
                        </b-form-checkbox>
                    </div>
                    <div class="form-group col-4">
                        <label>Text</label>
                        <input
                            v-model="product.shop_patch.text"
                            class="form-control"
                        />
                    </div>
                    <div class="form-group col-4">
                        <label>Text Color</label>
                        <color-picker
                            :key="product.id"
                            v-model="product.shop_patch.textColor"
                            :color="product.shop_patch.textColor"
                        />
                    </div>
                    <div class="form-group col-4">
                        <label>Background Color</label>
                        <color-picker
                            :key="product.id"
                            v-model="product.shop_patch.backgroundColor"
                            :color="product.shop_patch.backgroundColor"
                        />
                    </div>
                    <h5 class="col-12 text-uppercase bg-light p-2">
                        CTA button
                    </h5>
                    <div class="col-12 my-2">
                        <b-form-checkbox
                            v-model="product.cta_button_enabled"
                            name="enabled"
                            switch
                        >
                            Enabled
                        </b-form-checkbox>
                    </div>
                    <h5 class="col-12 text-uppercase bg-light p-2">
                        Price text
                    </h5>
                    <div class="col-12 my-2">
                        <b-form-checkbox
                            v-model="product.price_text.enabled"
                            name="enabled"
                            switch
                        >
                            Enabled
                        </b-form-checkbox>
                    </div>
                    <div v-if="product.price_text.enabled" class="col-12 my-2">
                        <product-price-text v-model="product.price_text" />
                    </div>
                </div>
            </div>
        </div>

        <div v-show="activeTab === 'extensions'">
            <product-extensions v-if="editMode && product.id" :product-id="product.id" />
        </div>

        <div v-show="activeTab === 'navigation-question'">
            <div class="card-box mb-0">
                <div class="row m-0">
                    <div class="form-group col-12 mt-2">
                        <b-form-checkbox
                            v-model="product.navigation_question_enabled"
                            switch
                            @change="onNavigationQuestionToggle"
                        >
                            Show Navigation Question
                        </b-form-checkbox>
                    </div>
                </div>
            </div>

            <navigation-question
                v-if="product.navigation_question_enabled && product.navigation_question"
                v-model="product.navigation_question"
                :questionnaires="product.questionnaires"
            />
        </div>

        <div v-show="activeTab === 'subactivity-question'">
            <div class="card-box mb-0">
                <div class="row m-0">
                    <div class="form-group col-12 mt-2">
                        <b-form-checkbox
                            v-model="product.subactivity_question_enabled"
                            switch
                            @change="onSubactivityQuestionToggle"
                        >
                            Subactivity Question Enabled
                        </b-form-checkbox>
                    </div>
                </div>
            </div>

            <subactivity-question
                v-if="product.subactivity_question_enabled && product.subactivity_question"
                v-model="product.subactivity_question"
                :questionnaires="product.questionnaires"
            />
        </div>

        <questionnaires-management
            v-if="editMode"
            v-show="activeTab === 'questionnaires'"
            :questionnaires="questionnaires"
            :product-slug="product.slug"
        />
    </layout>
</template>

<script>
import config from '@config';
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import ProductColors from '@components/products/ProductColors';
import ProductBenefits from '@components/products/ProductBenefits';
import ProductPriceText from '@components/products/ProductPriceText';
import ProductExtensions from '@components/products/ProductExtensions';
import NavigationQuestion from '@components/products/NavigationQuestion';
import SubactivityQuestion from '@components/products/SubactivityQuestion';
import QuestionnairesManagement from '@components/questionnaires/QuestionnairesManagement';
import ProductInformativeSections from '@components/products/ProductInformativeSections';
import ProductDetailsMixin from './productDetails.mixin';

export default {
    validations: {
        product: {
            name: {
                required
            },
            slug: {
                required
            },
            price: {
                required
            }
        }
    },

    components: {
        ProductColors,
        ProductBenefits,
        ProductPriceText,
        ProductExtensions,
        NavigationQuestion,
        SubactivityQuestion,
        ProductInformativeSections,
        QuestionnairesManagement
    },

    mixins: [ProductDetailsMixin],

    data() {
        return {
            pageTitle: 'Product Details',
            tabs: [
                {
                    value: 'product',
                    text: 'Product Information'
                },
                {
                    value: 'shop',
                    text: 'Shop Information'
                }
            ],
            activeTab: 'product',
            product: {
                benefits: {},
                shop_patch: {},
                price_text: {},
                quantity_selection: [],
                informative_sections: [],
                subactivity_question: {
                    options: []
                }
            },
            initValueStringify: '',
            questionnaires: [],
            insertsLengthOptions: [
                {
                    text: 'Full Length',
                    value: true
                },
                {
                    text: '3/4 Length',
                    value: false
                }
            ]
        };
    },

    computed: {
        previewUrl() {
            return `${config.frontendUrl}/products/${this.product.slug}`;
        }
    },

    mounted() {
        if (this.$route.query.questionnaire) {
            this.activeTab = 'questionnaires';
        }

        if (this.editMode) {
            this.tabs = [
                ...this.tabs,
                {
                    value: 'extensions',
                    text: 'Extensions'
                },
                {
                    value: 'questionnaires',
                    text: 'Questionnaires'
                },
                {
                    value: 'navigation-question',
                    text: 'Navigation Question'
                },
                {
                    value: 'subactivity-question',
                    text: 'Subactivity Question'
                }
            ];
        }
    },

    methods: {
        ...mapActions({
            storeProduct: 'products/store',
            findProduct: 'products/show',
            updateProduct: 'products/update',
            duplicateProduct: 'products/duplicate'
        }),

        changeTab(tab) {
            this.activeTab = tab.value;
        },

        markAsDefault(index) {
            this.product.quantity_selection = this.product.quantity_selection.map(
                quantity => {
                    quantity.default = false;

                    return quantity;
                }
            );

            this.product.quantity_selection[index].default = true;
        },

        deleteQuantitySelection(index) {
            this.product.quantity_selection.splice(index, 1);
        },

        addQuantitySelection() {
            this.product.quantity_selection.push({});
        },

        onNavigationQuestionToggle() {
            if (!this.product.navigation_question) {
                this.product.navigation_question = { options: [] };
            }
        },

        onSubactivityQuestionToggle() {
            if (!this.product.subactivity_question) {
                this.product.subactivity_question = { options: [] };
            }
        },
    }
};
</script>
